
<template>
    <b-container>
        <b-tabs>
            <b-tab title="Profile">
                <b-row class="m-2">
                    <b-col cols="2">
                        <ImageLoader
                            :image-url="profile.profile_pic_url || 'default_pic_url'"
                            :alt-text="'profile_pic_' + profile.username"
                            class="img-thumbnail "
                        />
                    </b-col>
                    <b-col cols="8">
                        <b-row>
                            <b-col>
                                <b-row>
                                    <b-col>
                                        <b-row class="ml-1">
                                            <h4>{{ profile.username }}</h4>
                                            <span v-if="profile.is_verified" class="ml-2 circle">
                                    <i class="fa fa-check"></i>
                                    </span>
                                        </b-row>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col><b>{{formatNumber(profile.media_count)}}</b> posts</b-col>
                                    <b-col><b>{{formatNumber(profile.follower_count)}}</b> followers</b-col>
                                    <b-col><b>{{formatNumber(profile.following_count)}}</b> following</b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b>{{ profile.full_name || 'not name found' }}</b>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b>{{ profile.biography || 'not biography found' }}</b>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <p>Category: {{ profile.category || 'not category found' }}</p>
                                    </b-col>
                                </b-row>
                                <b-row v-if="profile.address_street && profile.city_name">
                                    <b-col cols="12">
                                        <p>{{profile.address_street}}, {{profile.city_name}}</p>
                                    </b-col>
                                </b-row>
                                <b-row v-else>
                                    <b-col cols="12">
                                        <p>not address found</p>
                                    </b-col>
                                </b-row>
                                <b-row >
                                    <b-col cols="12" v-if="profile.external_url">
                                        <i class="fa fa-link mr-2"></i><b><a :href="profile.external_url">{{ profile.external_url }}</a></b>
                                    </b-col>
                                </b-row>
                                <b-row >
                                    <b-col cols="12" v-if="profile.public_email">
                                        <i class="fa fa-envelope mr-2"></i> <b><a :href="'mailto:' + profile.public_email">{{ profile.public_email }}</a></b>
                                    </b-col>
                                </b-row>
                                <b-row >
                                    <b-col cols="12" v-if="profile.contact_phone_number">
                                        <i class="fa fa-phone mr-2"></i> <b><a :href="'tel:' + profile.contact_phone_number">{{ profile.contact_phone_number }}</a></b>
                                    </b-col>
                                </b-row>
                                <b-row>
                                    <b-col cols="12">
                                        <b>{{ profile.business_email || 'not business email found' }}</b>
                                    </b-col>
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </b-tab>
            <b-tab title="Posts" active>
                <b-row>
                    <b-col v-for="post in posts" :key="post.id" cols="6" class="mb-2 d-flex align-content-center">
                        <b-card>
                            <b-card-text>
                                <p v-if="post.caption.text">
                                    {{ post.showFullCaption ? post.caption.text : truncatedCaption(post.caption.text) }}
                                    <span v-if="isLongCaption(post.caption.text)" @click="toggleCaption(post)" class="toggle-text">
                                    {{ post.showFullCaption ? ' Show Less' : ' Show More' }}
                                    </span>
                                </p>
                                <template v-if="post.product_type === productType.FEED">
                                    <div>
                                        <ImageLoader
                                            :image-url="post.image_versions2.candidates[0].url"
                                            :alt-text="'post_image_' + post.id"
                                            class="img-thumbnail"
                                        />

                                    </div>
                                </template>
                                <template v-else-if="post.product_type === productType.CAROUSEL_CONTAINER">
                                    <div>
                                        <CarrouselContent :carrousel-media="post.carousel_media" />
                                    </div>
                                </template>
                                <template v-else-if="post.product_type === productType.CLIPS">
                                    <div>
                                        <iframe width="auto" height="450px" :src="post.video_versions[0].url" allowfullscreen></iframe>
                                    </div>
                                </template>
                                <b class="mr-4" v-if="post.product_type === productType.CLIPS">
                                    <i class="fa fa-play mr-2"></i> {{formatNumber(post.play_count)}}
                                </b>
                                <b class="mr-4">
                                    <i class="fa fa-heart mr-2"></i> {{ formatNumber(post.like_count) }}
                                </b>
                                <b class="mr-4">
                                    <i class="fa fa-comment mr-2"></i> {{ formatNumber(post.comment_count) }}
                                </b>
                            </b-card-text>
                        </b-card>
                    </b-col>
                </b-row>
            </b-tab>

        </b-tabs>
    </b-container>

</template>

<script>
import ImageLoader from "@/components/instagramIntegration/imageLoader.vue";
import axios from "axios";
import CarrouselContent from "@/components/instagramIntegration/carrousel_content.vue";

export default {
    name: 'UserDetails',
    components: {CarrouselContent, ImageLoader},
        props: {
            searchId: {
                type: String,
                required: true
            },
            profile : {
                type: Object,
                required: true
            },
    },
    data() {
        return {
            posts: [],
            showFullCaption: false,
            maxLength: 100,
                productType : {
                    'FEED': 'feed',
                    'CAROUSEL_CONTAINER': 'carousel_container',
                    'CLIPS': 'clips',
                }
        }
    },
    mounted() {
        this.loadFeed();
    },
    methods: {
        truncatedCaption(caption) {
            return caption.length > this.maxLength
                ? caption.slice(0, this.maxLength) + '...'
                : caption;
        },
        isLongCaption(caption) {
            return caption.length > this.maxLength;
        },
        toggleCaption(post) {
            post.showFullCaption = !post.showFullCaption;
        },
        async loadFeed() {
            try {
                console.log(this.profile)
                const response = await axios.get(`${this.$root.serverUrl}/admin/instagram/feed`, {
                    'api-key': this.$root.apiKey,
                    params: {
                        searchId: this.searchId,
                        profileId: this.profile.pk
                    }
                });
              this.posts = response.data;
              this.posts = this.posts.map(post => {
                    return { ...post, showFullCaption: false };
                });
            } catch (error) {
                console.error(error);
            }
        },
        formatNumber(num) {
            if( num == null) {
                return 'N/A';
            }
            if (num >= 1e9) {
                return (num / 1e9).toFixed(2) + 'B';
            } else if (num >= 1e6) {
                return (num / 1e6).toFixed(2) + 'M';
            } else if (num >= 1e3) {
                return (num / 1e3).toFixed(2) + 'K';
            } else {
                return num.toString();
            }
        }
    }
};

</script>


<style>
.circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: green;
    border-radius: 50%;
}

.fa-check {
    color: white;
    font-size: 10px;
}
.toggle-text {
    color: blue;
    cursor: pointer;
}
</style>

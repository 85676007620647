<template>
  <section>
      <b-modal :id="modalId" size="xl" scrollable :title="title" @hide="onHide">

                  <div v-for="(profile, index) in profiles" :key="index">
                      <b-card-header header-tag="header">
                          <b-row class="d-flex align-items-center justify-content-between">
                              <b-col cols="2" class="d-flex justify-content-center">
                                  <ImageLoader
                                      class="img-thumbnail rounded-circle profile-pic"
                                      :image-url="profile.user.profile_pic_url"
                                      :alt-text="'profile_pic_' + profile.user.username"
                                  />
                              </b-col>
                              <b-col cols="9">
                                  <b-row class="d-flex align-items-center">
                                      <b-col cols="6" class="d-flex align-items-center">
                                          <b>{{ profile.user.full_name }}</b>
                                      </b-col>
                                      <b-col cols="6" class="d-flex align-items-center">
                                          <b>@{{ profile.user.username }}</b>
                                      </b-col>
                                  </b-row>
                              </b-col>
                              <b-col cols="1" class="d-flex justify-content-center">
                                  <b-button variant="primary" v-b-toggle="'accordion-' + index">
                                      <i :class="['fa', isAccordionOpen(index) ? 'fa-chevron-up' : 'fa-chevron-down']"></i>
                                  </b-button>
                              </b-col>
                          </b-row>
                      </b-card-header>
                      <b-collapse :id="'accordion-' + index"   accordion="my-accordion" role="tabpanel" class="mt-2">

                              <UserDetails :search-id="searchId" :profile="profile.user" />

                      </b-collapse>
                  </div>

      </b-modal>
      <b-button v-b-modal.user-details-modal>
          <i class="fa fa-eye"></i>
      </b-button>
  </section>

</template>

<script>

import UserDetails from "@/components/instagramIntegration/userDetails.vue";

export default {
    name: 'UserDetailsModal',
    components: {
        UserDetails,
        ImageLoader : () => import('@/components/instagramIntegration/imageLoader.vue')
    },
    props: {
        modalId: {
            type: String,
            required: true
        },
        title: {
            type: String,
            default: 'User Details'
        },
        profiles: {
          type: Array, required: true
        },
        searchId: {
            type: String,
            required: true
        }

    },
    data() {
      return {
          openAccordionIndex: null
      };
    },
    mounted() {
        this.$root.$on('bv::collapse::state', (collapseId, isJustShown) => {
            if (isJustShown) {
                const index = collapseId.split('-')[1];
                this.openAccordionIndex = parseInt(index);
            } else {
                this.openAccordionIndex = null;
            }
        });
    },
    methods: {
        isAccordionOpen(index) {
            return this.openAccordionIndex === index;
        },
        onHide() {
            this.$emit('hide');
        },

    }
}
</script>

<style>
.profile-pic {
    width: 75px;
    height: 75px;
    object-fit: cover;
}

</style>

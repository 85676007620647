<template>
    <img
        :src="imageSrc"
        :alt="altText"
        :class="vClass"
        @error="onImageError"
    />
</template>

<script>

export default {
    props: {
        imageUrl: {
            type: String,
            required: true
        },
        altText: {
            type: String,
            default: 'image'
        },
        vClass: {
            type: String,
            default: 'img-fluid'
        },
        placeholderUrl: {
            type: String,
            default: 'https://www.pngkey.com/png/detail/233-2332677_ega-png.png'
        }
    },
    mounted() {
        this.imageSrc = (`${this.$root.serverUrl}/static/image?url=${encodeURIComponent(this.imageUrl)}`);
    },
    data() {
        return {
            imageSrc: ''
        };
    },
    methods: {
        onImageError() {
            console.log('Error loading image');
            this.imageSrc = this.placeholderUrl;
        }
    }
};
</script>

<style scoped>
.img-fluid {
    max-width: 100%;
    height: auto;
}
</style>

<template>
    <div>
        <b-carousel
            id="carousel-1"
            v-model="slide"
            :interval="4000"
            controls
            indicators
            background="#ababab"
            img-width="1024"
            img-height="680"
            style="text-shadow: 1px 1px 2px #333;"
            @sliding-start="onSlideStart"
            @sliding-end="onSlideEnd"
        >
            <b-carousel-slide
                v-for="(media, index) in carrouselMedia"
                :key="index"
                :img-src="getFullUrl(media.image_versions2.candidates[0].url)"
            ></b-carousel-slide>
        </b-carousel>
    </div>
</template>

<script>
export default {
    name: 'CarrouselContent',
    props: {
        carrouselMedia: {
            type: Array,
            required: true
        }
    },
    data() {
        return {
            slide: 0,
            sliding: null
        };
    },
    methods: {
        getFullUrl(url) {
            return `${this.$root.serverUrl}/static/image?url=${encodeURIComponent(url)}`;
        },
        onSlideStart() {
            this.sliding = true;
        },
        onSlideEnd() {
            this.sliding = false;
        }
    }
};
</script>
<style>
.carousel-slide img {
    width: auto;
    height: 450px;
}
</style>
